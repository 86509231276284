:root {
  --primary-color: #188038; /* Bootstrap Primary Color Example */
  --secondary-color: #cbedda; /* Bootstrap Secondary Color Example */
}
*,
body{
  font-family: "Sora", serif;
}
.container-fluid.dim-bg {
  background: #f1f1f1;
  min-height: 100vh;
  padding: 60px;
}
.container-fluid.dim-bg > .row {
  background: #fff !important;
  box-shadow: 0 0 9px 3px hsla(0, 0%, 67%, 0.41);
  padding: 40px;
}
.floating-sidebar {
  background: #fff;
  border-radius: 0;
  min-height: 70vh;
  position: relative;
  width: 100%;
}
form {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures space between the input fields and button */
  gap: 20px;
}
.fix-btn-bg {
  margin-top: auto; /* Pushes the button to the bottom of the form */
  background-color: #188038; /* Or your preferred background color */
}
.from-to {
  background: #d5ffc8;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: auto;
  height: 100%;
}
.from,
.to {
  height: 55px;
  position: relative;
  width: 50%;
  margin: auto;
}
.from-to input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}
.from-to input,
.from-to label {
  padding-top: 14px;
  bottom: 0;
  display: block;
  font-weight: 700;
  font-family: "Sora", serif;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sidebar-booking .input-group-text,
.sidebar-booking input {
  border: 0;
  font-size: 14px;
}
/* .main-quote-page .flex.w-full{
  background: #fff !important;
  box-shadow: 0 0 9px 3px hsla(0, 0%, 67%, .41);
  padding: 40px 50px;
  gap: 20px;
} */
input:focus,
select:focus {
  border-color: #c3c3c3 !important;
  box-shadow: none !important;
}
.main-quote-page .journey-info{
  background: #e9e9e9;
  border-radius: 0;
  min-height: 70vh;
  padding: 25px;
}
.journey-info .back-btn{
  align-items: center;
  display: flex;
  padding-bottom: 0;
  color: #8f8f8f;
  margin-bottom: 10px;
}
.from-to .from,
.from-to .to {
  cursor: pointer;
}
.btn-main,
.btn-success {
  border-radius: 0 !important;
  background: #229556 !important;
}
.btn-default {
  border-radius: 0 !important;
  background-color: #cbedda !important;
}
.input-group-text {
  background-color: unset !important;
}
.circle-icon-green {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.circle-icon-red {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.journey-info h1 {
  color: #188038;
  display: flex;
  font-size: 22px;
  font-weight: 900;
  justify-content: space-between;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.left-side-card{
  align-items: center;
  border-bottom: 1px solid #e9dcdc;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.left-side-card div{
  margin-left: 20px;
}
.left-side-card .card-heading1{
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 0;
}
.left-side-card p{
  font-size: 15px;
  font-weight: 600;
}
.right-form-2nd .form-group,
.modal-card .form-group,
.payment-fields .form-group{
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  border: 2px solid #6a6a6a;
  border-radius: 0 !important;
}
.right-form-2nd .form-group .field-style,
.modal-card .form-group .field-style,
.payment-fields .form-group .field-style{
  border-radius: 0;
  height: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
  font-size: 14px;
  font-weight: 400;
  border: 0px transparent;
}
.right-form-2nd .form-group .field-icon,
.modal-card .form-group .field-icon,
.payment-fields .form-group .field-icon{
  padding:.375rem .75rem;
}
.modal-card .form-group{
  margin-bottom: 10px;
}
.modal-card .add-card-btn-modal{
  background: #188038;
  border: 0;
  border-radius: 0;
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: .2px;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;

}
.tel-number-field select{
  flex-basis: 35%;
}
.tel-number-field input{
  flex-basis: 65%;
}
.pay-with-card{
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  cursor: pointer;
}
.add-card-btn{
  background: #3c3c3c;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 16px 0 !important;
  position: static;
  text-align: center;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.confirm-order{
  background: #188038;
  border: 0;
  border-radius: 0;
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: .2px;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}
.modal-close-btn{
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 30px;
  width: 100% !important;
}
.modal-heading{
  font-size: 16px;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.toggle-btn {
  background: none;
  border: none;
  color: #229556;
  font-size: 24px;
  cursor: pointer;
  display: none;
}
.nav-main .navbar{
  width: 100%;
  justify-content: space-between !important;
}
@media (max-width: 992px) {
  .toggle-btn {
    display: block;
  }

  .nav-container .nav {
    display: none;
    flex-direction: column;
    background: #444;
    position: absolute;
    width: 100%;
    left: 0;
    top: 50px;
    padding: 10px 0;
  }

  .nav-container .nav.show {
    display: flex;
    background: #e7e7e7;
    height: 430px !important;
    left: 2px;
    padding: 30px 21px;
    position: absolute;
    top: 61px;
    transition: height .3sease;
    width: 100%;
    z-index: 999999999999;
    align-items: center;
    flex-wrap: nowrap;
  }
  .nav-container .nav.show li{
    padding: 15px 20px !important;
    text-align: center;
  }
}
@media(max-width:992px){
  .floating-sidebar form,
  .floating-sidebar{
    min-height: auto;
  }
}
@media(max-width:768px){
  .container-fluid.dim-bg{
    padding: 20px;
  }
  .container-fluid.dim-bg > .row{
    padding: 20px 10px;
  }
  .from-to input, .from-to label{
    font-size: 16px;
  }
  .main-quote-page .journey-info{
    min-height: auto;
    margin-bottom: 30px;
  }
}