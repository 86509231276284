:root {
  --primary-color: #188038; /* Bootstrap Primary Color Example */
  --secondary-color: #cbedda; /* Bootstrap Secondary Color Example */
}

body {
  margin: 0;
  font-family: "Sora", serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100% !important;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
span.fleet-icons {
  margin-left: 30px;
  font-size: 15px;
  font-family: "Sora", serif;

}
.fleet-icons svg {
  color: #5d5d5d;
  font-size: 13px;
  font-family: "Sora", serif;
  padding-right: 3px;
}
.summary-card {
  border-bottom: 1px solid #cbcbcb;
}
.logo {
  margin-bottom: 0;
  margin-right: 20px;
}

.nav {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.form-control{
  font-family: "Sora", serif;
  font-weight: 600 !important;
  font-size: 14px;
}
.btn-main, .btn-success {
  border-radius: 0 !important;
  background: #229556 !important;
}
.nav li {
  padding: 0 20px;
}

.nav a {
  color: #4e4e4e;
  font-weight: 700;
  font-size: 1rem;
  font-family: "Sora", serif;
  transition: color 0.3s;
  text-decoration: none !important;
}

.loading-bg {
  background-color: rgba(0, 0, 0, 0.5); /* Dim effect */
  min-height: 100vh; /* Full screen */
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8); /* Light overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.nav a:hover {
  color: #229558;
}

.logo img {
  width: 40px;
}
.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #229558;
}
.container-fluid.dim-bg {
  background: #f1f1f1;
  min-height: 100vh;
  padding: 60px;
  margin: auto;
}
